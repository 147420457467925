import { Box, Button, Flex, Icon, Text, useDisclosure } from '@chakra-ui/core'
import { UseGoogleLogin } from '@components/GoogleLogin/GoogleLogin'
import I18nLanguageSelector from '@components/i18nLanguageSelector'
import { isLanguageSelectionEnabled } from '@src/constent'
import { paths } from '@src/routers/constants'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { setError } from '../../app/appSlice'
import { RootState } from '../../app/RootReducer'
import NewModal from '../../components/NewModal'
import { openLoginLinkingModal } from '../OtplessV3'
import { NotPremiumStreamerOnLocoModal } from './NotPremiumStreamerOnLocoModal'

interface GoogleAndMobileOptionLoginScreenProps {
  responseGoogle: (response: any) => void
}
export const GoogleAndMobileOptionLoginScreen = ({
  responseGoogle,
}: GoogleAndMobileOptionLoginScreenProps): JSX.Element => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { isOpen, onClose, onOpen } = useDisclosure()
  const { error } = useSelector((state: RootState) => state.app)

  useEffect(() => {
    if (error) {
      onOpen()
    }
  }, [error])

  const handleClose = () => {
    dispatch(setError(null))
    onClose()
  }

  return (
    <Flex
      flex={1}
      bg="brand.loco-black"
      color={'white'}
      minH={['40vh', '10vh']}
      height={['45vh', '600px']}
      width={['100%', '360px']}
      direction="column"
      justifyContent="center"
      px={[0]}
    >
      {window.innerWidth < 640 ? null : isLanguageSelectionEnabled() ? (
        <Flex
          color="white"
          display={['none', 'flex']}
          alignItems="center"
          justifyContent="flex-end"
          mr="16px"
          position="relative"
        >
          {/* THere must be only 1 instance of this */}
          <I18nLanguageSelector origin="login_modal" />
        </Flex>
      ) : null}

      <Flex
        flex={1}
        px={['36px', '40px']}
        h="full"
        w="full"
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Text
          display={['block', 'none']}
          fontSize="12px"
          lineHeight={'130%'}
          textAlign="center"
        >
          {t('login.header_subtitle')}
        </Text>
        <Flex
          flex={[0, 1]}
          w="100%"
          direction="column"
          justifyContent="center"
          my={['24px', '0px']}
          maxW="300px"
        >
          <Box
            mb="36px"
            fontWeight="900"
            fontSize={'20px'}
            lineHeight={'25.94px'}
            textAlign="center"
            display={['none', 'flex']}
          >
            {t('login.header_subtitle')}
          </Box>

          <UseGoogleLogin
            render={(renderProps) => {
              return (
                <Button
                  w="full"
                  py={['8px', '12px']}
                  px={['16px', '24px']}
                  rounded="10px"
                  bg="brand.loco-primary"
                  color="white"
                  fontWeight={['900', '700']}
                  fontSize={['14px', '16px']}
                  lineHeight={['14px', '130%']}
                  letterSpacing={['-2%', 'auto']}
                  minH={['48px', '48px']}
                  outline="none"
                  _focus={{
                    outline: 'none',
                  }}
                  _hover={{
                    outline: 'none',
                    background: 'brand.loco-primary',
                  }}
                  _active={{
                    outline: 'none',
                    background: 'brand.loco-primary',
                  }}
                  onClick={renderProps.onClick}
                >
                  <Icon
                    name="googleWhiteIcon"
                    size="24px"
                    style={{
                      marginInlineEnd: '0.5rem',
                    }}
                  />
                  {t('login.login_with_google')}
                </Button>
              )
            }}
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
          />
          <Button
            mt={['24px', '16px']}
            w="full"
            py={['8px', '12px']}
            px={['16px', '24px']}
            rounded="10px"
            bg="brand.loco-primary"
            color="white"
            fontWeight={['900', '700']}
            fontSize={['14px', '16px']}
            lineHeight={['14px', '130%']}
            letterSpacing={['-2%', 'auto']}
            minH={['48px', '48px']}
            outline="none"
            _focus={{
              outline: 'none',
            }}
            _hover={{
              outline: 'none',
              background: 'brand.loco-primary',
            }}
            _active={{
              outline: 'none',
              background: 'brand.loco-primary',
            }}
            onClick={() => {
              dispatch(openLoginLinkingModal({ modal_type: 'PHONE_LOGIN' }))
            }}
          >
            <Icon
              name="phoneWhiteIcon"
              size="24px"
              style={{
                marginInlineEnd: '0.5rem',
              }}
            />
            {t('login.login_with_phone_number')}
          </Button>
        </Flex>
        <Text
          pb={['65px', '32px']}
          textAlign="center"
          fontSize={['12px', '12px']}
          lineHeight="130%"
          whiteSpace="nowrap"
        >
          {t('login.terms_and_condition_prefix')}{' '}
          <a
            href={paths.terms}
            target="_blank"
            style={{
              display: 'inline',
              cursor: 'pointer',
              textDecoration: 'underline',
            }}
            rel="noreferrer"
          >
            {t('login.terms_and_condition')}
          </a>
        </Text>
      </Flex>

      <NewModal
        isOpen={isOpen}
        onClose={handleClose}
        modalSize="md"
        isCentered={true}
        closeOnOverlayClick={true}
        scrollBehaviour="outside"
        modalOverlayStyle={{
          zIndex: 1400,
        }}
        modalContentStyle={{
          bg: 'brand.primary-light-black-v4',
        }}
        modalHeaderStyle={{
          px: 0,
          pb: 0,
        }}
        modalBodyStyle={{
          p: 4,
        }}
        modalBodyComponent={
          <NotPremiumStreamerOnLocoModal onClose={handleClose} />
        }
      />
    </Flex>
  )
}
